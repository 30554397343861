<template>
    <div class="spacer-navigation-overlap row">
        <div class="col-12 spacer-60-50">
            <div class="slider">
                <Slider
                    :wrapAround="true"
                    :showCurrent="true"
                    :slides="location.images"
                    :itemsToShow="1"
                    :breakpoints="{}"
                >
                    <template #default="image">
                        <NuxtImg
                            sizes="xs:630 sm:767px md:1023px lg:1710px"
                            densities="1x 2x"
                            format="webp"
                            :src="image.src"
                            :alt="image.alt"
                            loading="lazy"
                            class="slider__image"
                            :placeholder="[300, 200, 60, 2]"
                        />
                    </template>
                </Slider>
                <hr />
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-640-8 spacer-footer">
            <div
                class="equal-base equal-base--tighter-bold-uppercase restaurant__location"
            >
                {{ location?.location }}
            </div>
            <h1
                class="scale-6 scale-6--bold-uppercase restaurant__name"
                :id="anchorTitle"
            >
                {{ page.title }}
            </h1>
            <div class="scale-2 scale-2--lead restaurant__lead">
                {{ location?.lead }}
            </div>
            <div
                class="equal-base restaurant__text"
                v-html="location?.text"
            ></div>
            <div class="d-flex flex-column flex-800-row information">
                <div v-if="location?.address" class="address">
                    <h2
                        class="scale-2 scale-2--bold-uppercase spacer-25-20"
                        :id="slugifyString(t('location.detail.address'))"
                    >
                        {{ t('location.detail.address') }}
                    </h2>
                    <div class="d-flex flex-column address__direction">
                        <span class="equal-base equal-base--tighter-bold">
                            {{ location?.address?.name }}
                        </span>
                        <span class="equal-base equal-base--tighter">
                            {{ location?.address?.street }}
                            {{ location?.address?.house_number }}
                        </span>
                        <span class="equal-base equal-base--tighter">
                            {{ location?.address?.postal_code }}
                            {{ location?.address?.city }}
                        </span>
                    </div>
                    <NuxtLink
                        :to="location?.maps?.to"
                        class="equal-base equal-base--tighter link link--animated link--external address__maps"
                        :target="getTarget(location?.maps?.to)"
                    >
                        {{ location?.maps?.label }}
                    </NuxtLink>
                </div>
                <div
                    v-if="location?.contact"
                    class="d-flex flex-column contact"
                >
                    <h2
                        class="scale-2 scale-2--bold-uppercase spacer-25-20"
                        :id="slugifyString(t('location.detail.contact'))"
                    >
                        {{ t('location.detail.contact') }}
                    </h2>
                    <ul class="contact__options">
                        <li v-if="location?.contact?.email">
                            <NuxtLink
                                :to="'mailto:' + location?.contact.email?.value"
                                class="equal-base equal-base--tighter link link--animated contact__item"
                            >
                                {{ location?.contact.email?.label }}
                            </NuxtLink>
                        </li>
                        <li v-if="location?.contact?.phone">
                            <NuxtLink
                                :to="'tel:' + location?.contact.phone?.value"
                                class="equal-base equal-base--tighter link link--animated"
                            >
                                {{ location?.contact.phone?.label }}
                            </NuxtLink>
                        </li>
                        <li v-if="location?.contact?.homepage">
                            <NuxtLink
                                :to="location?.contact.homepage?.value"
                                class="equal-base equal-base--tighter link link--animated link--external"
                                :target="
                                    getTarget(location?.contact.homepage?.value)
                                "
                            >
                                {{ location?.contact.homepage?.label }}
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { getTarget } from '~/utils/externalLink';
import { useHead } from 'nuxt/app';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { slugifyString } from '~/utils/slugify';

const { t } = useI18n();

const location = computed(() => page?.value?.data);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useHrefLang(page?.value?.meta?.hreflang);

const anchorTitle = computed(() => useAnchorTitle(page?.value?.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slider {
    @include media-breakpoint-down(800) {
        :deep(.slider) {
            margin-left: $padding-container-negative;
            margin-right: $padding-container-negative;
        }

        :deep(.slider__navigation) {
            margin-left: (-$padding-container-negative);
            margin-right: (-$padding-container-negative);
        }
    }

    &__image {
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        width: 100%;
        object-fit: cover;
    }

    :deep(.slider__slide) {
        padding: 0;
    }

    hr {
        color: $color-dark-01;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.restaurant {
    &__location {
        color: $color-disabled;
        margin-bottom: 10px;
    }

    &__name {
        margin-bottom: 15px;
    }

    &__lead {
        margin-bottom: clamp(50px, 1.39vw + 45.56px, 70px);
    }

    &__text {
        margin-bottom: clamp(80px, 0.69vw + 77.78px, 90px);
    }
}

.information {
    & > div {
        &:not(:last-child) {
            margin-bottom: 50px;
        }
    }
}

.address {
    width: 50%;

    @include media-breakpoint-down(800) {
        width: 100%;
    }

    &__direction {
        span {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
    }

    &__maps {
        margin-top: 14px;
    }
}

.contact {
    width: 50%;

    @include media-breakpoint-down(800) {
        width: 100%;
    }

    &__options {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
    }
}
</style>
